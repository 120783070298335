import React, { useMemo, useState } from 'react';
import Footer from '../../components/Footer';
import CancelSubscriptionModal from '../../../common/CancelSubscriptionModal';
import { Box, Dialog, Typography } from '@mui/material';
import BaseButton from '../../components/Button';
import ArrowIcon from '../../../../assets/svg/arrow-narrow-left.svg';
import checked_icon from '../../../../assets/images/dashboard/unsubscribe/checked_icon.svg';
import prof_report from '../../../../assets/images/dashboard/unsubscribe/image_profile_report.webp';
import mastery_course from '../../../../assets/images/dashboard/unsubscribe/image_instagram_mastery_course.webp';
import {
  AppRoutes,
  FETCHING_TIMEOUT,
  PDF_COURSE_PRICE,
  PROFILE_REPORT_PRICE,
} from '../../../../utils/constants';
import SubscriptionCard from './SubscriptionCard';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import download from '../../../../assets/svg/download.svg';
import { getSubscriptionInfo } from '../../../../utils/subscription';

function ManageSubscriptions({ handleClickByMenuItem }: { handleClickByMenuItem: (s: AppRoutes) => void }) {
  const { isManageSubscriptionsOpen, setManageSubscriptionsOpen } = useAppState();
  const [isCancelSubsModalOpen, setCancelSubsModalOpen] = useState(false);

  const {
    isHasProfileReportSubscription,
    isHasPdfCourseSubscription,
    subscriptionStartedAt,
    isSubscriptionCanceled,
    userSubscription,
  } = useAppState();

  const subscriptions = useMemo(() => {
    return [
      {
        img: prof_report,
        title: 'Detailed profile report',
        purchasedTitle: 'Report is on the Way',
        purchasedSubTitle:
          "We've sent your detailed profile report to your email. Please check your inbox—and don’t forget to look in your spam or junk folder, just in case!",
        subTitle:
          'Uncover the secrets to boost your Instagram with a tailored report analysing your profile, and forget about low engagement',
        btnText: 'Get profile report',
        btnPurchasedText: null,
        prevPrice: PROFILE_REPORT_PRICE.fullPrice,
        currPrice: PROFILE_REPORT_PRICE.sum,
        discountPercent: PROFILE_REPORT_PRICE.discount,
        planId: window.profile_report_id,
        handleDownloadClick: () => {},
        isPurchased: isHasProfileReportSubscription,
        sendReportData: true,
      },
      {
        img: mastery_course,
        title: 'Instagram Mastery Course',
        subTitle:
          'Learn the secrets of top influencers, develop a content strategy that works, and get insider tips on maximizing engagement',
        btnText: 'Get Instagram course',
        btnPurchasedText: (
          <>
            <img
              style={{
                maxWidth: '24px',
              }}
              src={download}
              alt="download"
            ></img>
            Download Instagram course
          </>
        ),
        prevPrice: PDF_COURSE_PRICE.fullPrice,
        currPrice: PDF_COURSE_PRICE.sum,
        discountPercent: PDF_COURSE_PRICE.discount,
        planId: window.pdf_course_id,
        openModal: () => {},
        handleDownloadClick: () => {
          handleClickByMenuItem(AppRoutes.PDF_COURSE);
        },
        isPurchased: isHasPdfCourseSubscription,
      },
    ];
  }, [isHasProfileReportSubscription, isHasPdfCourseSubscription, handleClickByMenuItem]);

  const isFetching = useMemo(() => {
    if (subscriptionStartedAt) {
      const timezoneOffsetMs = new Date().getTimezoneOffset() * 60 * 1000;
      const adjustedSubsStartDate = new Date(new Date(subscriptionStartedAt).getTime() - timezoneOffsetMs);
      const differenceInMs = new Date().getTime() - adjustedSubsStartDate.getTime();
      return differenceInMs < FETCHING_TIMEOUT;
    } else {
      return false;
    }
  }, [subscriptionStartedAt]);

  return (
    <Dialog fullScreen open={isManageSubscriptionsOpen}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: '430px',
          margin: 'auto',
        }}
      >
        <Box
          onClick={() => {
            setManageSubscriptionsOpen(false);
          }}
          sx={{
            height: '78px',
            minHeight: '78px',
            borderBottom: '1px solid #23263110',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <img
            style={{
              position: 'absolute',
              left: '20px',
            }}
            src={ArrowIcon}
            alt="arrow"
          ></img>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: 'normal',
              color: '#232631',
              userSelect: 'none',
            }}
          >
            My Plan
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            padding: '0 20px',
          }}
        >
          <Box
            sx={{
              borderRadius: '16px',
              padding: '8px 15px',
              background: 'linear-gradient(270deg, #FF9A84 0%, #EF5DCE 50%, #2B42DB 100%)',
              width: 'max-content',
              fontFamily: 'Avenir Next',
              fontWeight: 700,
              fontSize: '10px',
              lineHeight: 'normal',
              marginTop: '30px',
              color: '#FFF',
            }}
          >
            OUR SPECIAL OFFERS
          </Box>

          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: 'normal',
              color: '#232631',
              textAlign: 'center',
              userSelect: 'none',
              marginTop: '15px',
              marginBottom: '10px',
            }}
          >
            Boost Your Instagram Success with our Special Offers
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: 'normal',
              color: '#8C8E96',
              textAlign: 'center',
              userSelect: 'none',
              marginBottom: '30px',
            }}
          >
            Get insider strategies for better engagement, content planning, and maximizing reach!
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              flexDirection: 'column',
            }}
          >
            {subscriptions.map((el) => (
              <SubscriptionCard key={el.title} {...el} />
            ))}
          </Box>
          <Box
            sx={{
              width: '100%',
              margin: '40px 0',
              minHeight: '1px',
              background: '#E6E7EB',
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: 'normal',
              color: '#232631',
              textAlign: 'center',
              userSelect: 'none',
              marginBottom: '15px',
            }}
          >
            My active plan
          </Typography>
          <Box
            sx={{
              border: '1px solid rgba(35, 38, 49, 0.10)',
              borderRadius: '20px',
              padding: '15px',
              width: '100%',
              display: 'flex',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                fontFamily: 'Avenir Next',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: 'normal',
                color: '#232631',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              {isFetching ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '32px',
                      height: '32px',
                      position: 'relative',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span className="plan_fetching_loader"></span>
                  </Box>
                  Fetching plans
                </>
              ) : (
                <>
                  <img src={checked_icon} alt="checked_icon"></img>
                  {userSubscription && getSubscriptionInfo(userSubscription).name}
                </>
              )}
            </Box>
            {isSubscriptionCanceled ? (
              <BaseButton
                disabled
                sx={{
                  background: 'rgba(229, 62, 62, 0.10)',
                  width: '100%',
                  maxWidth: '169px',
                  padding: '13px 0px',
                  borderRadius: '12px',
                  fontFamily: 'Avenir Next',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: '#E53E3E',
                  textTransform: 'initial',

                  '&:disabled': {
                    color: '#8C8E9650',
                    background: '#F5F2ED',
                  },
                  '&:hover': {
                    background: 'rgba(229, 62, 62, 0.10)',
                  },
                }}
                onClick={() => {
                  setCancelSubsModalOpen(true);
                }}
              >
                Canceled
              </BaseButton>
            ) : (
              <BaseButton
                disabled={isFetching}
                sx={{
                  background: 'rgba(229, 62, 62, 0.10)',
                  width: '100%',
                  maxWidth: '169px',
                  padding: '13px 0px',
                  borderRadius: '12px',
                  fontFamily: 'Avenir Next',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: '#E53E3E',
                  textTransform: 'initial',

                  '&:disabled': {
                    color: '#8C8E9650',
                    background: '#F5F2ED',
                  },
                  '&:hover': {
                    background: 'rgba(229, 62, 62, 0.10)',
                  },
                }}
                onClick={() => {
                  setCancelSubsModalOpen(true);
                }}
              >
                Cancel
              </BaseButton>
            )}
          </Box>

          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: 'normal',
              color: '#8C8E96',
              textAlign: 'center',
              userSelect: 'none',
              marginTop: '15px',
              span: {
                fontWeight: 600,
                color: '#FF344C',
              },
            }}
          >
            Canceling your membership now means <span>losing access</span> to Instagram analysis and valuable
            improvement tips that boost your growth
          </Typography>
        </Box>
        <Box sx={{ marginTop: '56px', width: '100%' }}>
          <Footer />
        </Box>
        {isCancelSubsModalOpen && (
          <CancelSubscriptionModal isModalOpen={isCancelSubsModalOpen} setModalOpen={setCancelSubsModalOpen} />
        )}
      </Box>
    </Dialog>
  );
}

export default ManageSubscriptions;
