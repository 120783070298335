import { Box, Typography, useTheme } from '@mui/material';
import { AppRoutes } from '../../../../utils/constants';
import NavigationCard from './components/NavigationCard';
// import AnalyticsImage from '../../../../assets/images/dashboard/navigation-card/analytics-bg-card.webp';
// import InsightsImageOp from '../../../../assets/images/dashboard/navigation-card/insights--op-bg-card.webp';
import InsightsImage from '../../../../assets/images/dashboard/navigation-card/insights-bg-card.webp';
import InstProImage from '../../../../assets/images/dashboard/navigation-card/instagram-pro-bg-card.webp';
import NewsImage from '../../../../assets/images/dashboard/navigation-card/news-bg-card.webp';
import AdmirersImage from '../../../../assets/images/dashboard/navigation-card/admirers.webp';
import StalkersImage from '../../../../assets/images/dashboard/navigation-card/stalkers.webp';
import PasswordImage from '../../../../assets/images/dashboard/navigation-card/password-bg-card.webp';
import TopTrends from '../../../../assets/images/dashboard/navigation-card/top_trends.svg';
import TopHacks from '../../../../assets/images/dashboard/navigation-card/top_hacks.svg';
import ArrowRight from '../../../../assets/images/dashboard/navigation-card/arrow_right.svg';
// import background from '../../../../assets/svg/dashboard_mobile_background.svg';

import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../../../providers/AppProvider.hooks';
import PdfCoursePurchaseModal from '../../../common/PdfCoursePurchaseModal';
// import useEstimatedInsightsResponse from '../../hooks/useEstimatedInsightsResponse';

function Main() {
  // const [timeLeft, onClickByInsightsCard] = useEstimatedInsightsResponse();
  const theme = useTheme();
  const { isHasPdfCourseSubscription, setPdfCoursePurchaseModalOpen, isPdfCoursePurchaseModalOpen } = useAppState();
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 24px 56px',
          gap: '15px',
          paddingTop: '111px',
          background: '#DAE2FF',
        }}
      >
        <Box
          sx={{
            borderRadius: '24px',
            border: '1px solid #00B2E9',
            padding: '20px',
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontSize: '10px',
              fontWeight: 700,
              lineHeight: 'normal',
              display: 'flex',
              padding: '8px 12px',
              color: '#fff',
              textTransform: 'uppercase',
              width: 'max-content',
              borderRadius: '16px',
              background: 'linear-gradient(270deg, #00B2E9 0%, #068AFF 50%, #A332EE 100%)',
              position: 'absolute',
              top: -16,
            }}
          >
            Uncover 2025’s top Instagram trends
          </Typography>
          <Box
            onClick={() => {
              navigate(AppRoutes.TOP_TRENDS);
            }}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <img src={TopTrends} alt="top"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontWeight: 600,
                lineHeight: 'normal',
                fontSize: '16px',
                color: theme.palette.text.primary,
              }}
            >
              Top 7 Instagram Trends That Will Define 2025
            </Typography>
            <img src={ArrowRight} alt="arrow"></img>
          </Box>
          <Box
            sx={{
              height: '1px',
              width: '100%',
              background: '#232631',
              opacity: 0.1,
              margin: '20px 0',
            }}
          />
          <Box
            onClick={() => {
              navigate(AppRoutes.TOP_HACKS);
            }}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <img src={TopHacks} alt="top"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontWeight: 600,
                lineHeight: 'normal',
                fontSize: '16px',
                color: theme.palette.text.primary,
              }}
            >
              Top 5 Instagram Algorithm Hacks to Grow in 2025
            </Typography>
            <img src={ArrowRight} alt="arrow"></img>
          </Box>
        </Box>
        {/* <NavigationCard
          title="Analytics"
          description="Analysis of your account metrics and statistics"
          onClick={() => navigate(AppRoutes.ANALYTICS)}
          image={AnalyticsImage}
        /> */}
        <NavigationCard
          title="Tips & Insights"
          description="Find out how to improve your account metrics"
          onClick={() => navigate(`${AppRoutes.INSIGHTS}`)}
          timeLeft={null}
          image={InsightsImage}
        />
        <NavigationCard
          title="Secret Admirers"
          description="Know who’s watching your profile without following you"
          onClick={() => navigate(`${AppRoutes.ARTICLES}/secret-admirers`)}
          timeLeft={null}
          image={AdmirersImage}
        />
        <NavigationCard
          title="Detect Stalkers"
          description="Learn how to identify, address, and prevent stalking or data breaches"
          onClick={() => navigate(`${AppRoutes.ARTICLES}/detect-stalkers`)}
          timeLeft={null}
          image={StalkersImage}
        />
        <NavigationCard
          title="Password safety"
          description="Find out if all your passwords are securely protected"
          onClick={() => navigate(AppRoutes.PASSWORD_SAFETY)}
          image={PasswordImage}
        />
        <NavigationCard
          title="News"
          description="Stay up to date with current and popular trends"
          onClick={() => navigate(AppRoutes.NEWS)}
          image={NewsImage}
        />
        <NavigationCard
          label="10-lessons course"
          title="Instagram PRO"
          description="Strategies to boost engagement on your Instagram profile"
          onClick={() => {
            if (isHasPdfCourseSubscription) {
              navigate(AppRoutes.PDF_COURSE);
            } else {
              setPdfCoursePurchaseModalOpen(true);
            }
          }}
          image={InstProImage}
        />
      </Box>
      <PdfCoursePurchaseModal
        isModalOpen={isPdfCoursePurchaseModalOpen}
        setModalOpen={setPdfCoursePurchaseModalOpen}
      ></PdfCoursePurchaseModal>
    </>
  );
}

export default Main;
