import { Box, Button, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import { useAppState } from '../../../providers/AppProvider.hooks';
import header_image from '../../../assets/images/unsubscribe/header_image.webp';
import check_verify from '../../../assets/images/unsubscribe/check_verified.svg';
import p1 from '../../../assets/images/unsubscribe/p1.svg';
import p2 from '../../../assets/images/unsubscribe/p2.svg';
import p3 from '../../../assets/images/unsubscribe/p3.svg';
import p4 from '../../../assets/images/unsubscribe/p4.svg';
import p5 from '../../../assets/images/unsubscribe/p5.svg';
import point_checkmark from '../../../assets/images/unsubscribe/point_checkmark.svg';

function Step1({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const { setUnsubscribeOpen } = useAppState();
  const theme = useTheme();

  const points = [
    {
      title: 'Data-driven growth insights',
      subTitle: 'Optimize your engagement and reach',
      img: p1,
    },
    {
      title: 'IG trends & algorithm hacks',
      subTitle: 'Know exactly what works',
      img: p2,
    },
    {
      title: 'Expert strategy guides',
      subTitle: 'Boost visibility, interaction & followers',
      img: p3,
    },
    {
      title: 'Content optimization tips',
      subTitle: 'From perfect captions to best visuals',
      img: p4,
    },
    {
      title: 'Smarter decisions that win',
      subTitle: 'No more guessing',
      img: p5,
    },
  ];

  return (
    <Box>
      <StepHeader
        onClick={() => {
          setUnsubscribeOpen(false);
        }}
        title="My active plan"
      />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          img: {
            width: '100%',
          },
        }}
      >
        <img src={header_image} alt="decor" />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '16px',
            background: 'linear-gradient(270deg, #FF9A84 0%, #EF5DCE 50%, #2B42DB 100%)',
            padding: '8px 15px 8px 10px',
            gap: '5px',
            fontFamily: 'Avenir Next',
            fontWeight: 700,
            lineHeight: 'normal',
            fontSize: '10px',
            textTransform: 'uppercase',
            color: '#FFF',
            img: {
              width: '20px',
            },
          }}
        >
          <img src={check_verify} alt="checked"></img>
          Premium plan BENEFITS
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '15px 20px 0',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            marginBottom: '10px',
            textAlign: 'center',
            span: {
              color: theme.palette.common.blue,
            },
          }}
        >
          Stay subscribed & <span>keep growing!</span>
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          By keeping your membership, you’ll continue to get:
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          {points.map((el, i) => (
            <Box
              key={i}
              sx={{
                background: 'rgba(37, 107, 253, 0.10)',
                borderRadius: '16px',
                padding: '15px',
                position: 'relative',
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '-5px',
                  right: '-5px',
                }}
              >
                <img src={point_checkmark} alt="decor" />
              </Box>

              <img src={el.img} alt="point"></img>
              <Box>
                <Typography
                  sx={{
                    fontFamily: 'Avenir Next',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    fontSize: '16px',
                    color: theme.palette.text.primary,
                  }}
                >
                  {el.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Avenir Next',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    fontSize: '14px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {el.subTitle}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Button
          onClick={() => {
            setUnsubscribeOpen(false);
          }}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
          }}
        >
          Stay premium
        </Button>
        <Typography
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontWeight: 400,
            fontFamily: 'Avenir Next',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          I'm ready to let go of my growth
        </Typography>
      </Box>
    </Box>
  );
}

export default Step1;
