import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Dashboard, SignIn } from './components';
import { Analytics, Insights, News, NewsPost, PdfCourse, PasswordSafety } from './components/Dashboard/pages';
import { AppProvider } from './providers/AppProvider';
import { useAppState } from './providers/AppProvider.hooks';
import LogOutModal from './components/common/LogOutModal';
import { AppRoutes, mediaQueries } from './utils/constants';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import ProvideRoute from './components/ProvideRoute';
import Theme from './providers/ThemeProvider';
import { useMediaQuery } from '@mui/material';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import SomethingWentWrong from './components/SignIn/components/SomethingWentWrong';
import usePopState from './hooks/usePopState';
import AccessRecovery from './components/AccessRecovery/AccessRecovery';
import EmailSended from './components/AccessRecovery/EmailSended';
import { startLogoutTimer } from './utils/logout';
import CancelSubscription from './components/CancelSubscription';
import Article from './components/Dashboard/pages/Articles/components/Article';
import Articles from './components/Dashboard/pages/Articles/Articles';
import RestorePassword from './components/common/RestorePassword';
import TopTrends from './components/Dashboard/pages/TopTrends/TopTrends';
import TopHacks from './components/Dashboard/pages/TopHacks/TopHacks';

console.log('0.04');

const router = createBrowserRouter([
  {
    path: AppRoutes.HOME,
    element: <ProvideRoute />,
  },
  {
    path: AppRoutes.SIGN_IN,
    element: <PublicRoute component={SignIn} />,
  },
  {
    path: AppRoutes.ACCESS_RECOVERY,
    element: <PublicRoute component={AccessRecovery} />,
  },
  {
    path: AppRoutes.PASSWORD_RECOVERY,
    element: <PublicRoute component={RestorePassword} />,
  },
  {
    path: AppRoutes.DASHBOARD,
    element: <PrivateRoute component={Dashboard} />,
  },
  {
    path: AppRoutes.ANALYTICS,
    element: <PrivateRoute component={Analytics} />,
  },
  {
    path: AppRoutes.ARTICLES + '/:slug',
    element: <PrivateRoute component={Articles} />,
  },
  {
    path: AppRoutes.ARTICLE + '/:slug/:subSlug',
    element: <PrivateRoute component={Article} />,
  },
  { path: AppRoutes.INSIGHTS, element: <PrivateRoute component={Insights} /> },
  { path: AppRoutes.TOP_TRENDS, element: <PrivateRoute component={TopTrends} /> },
  { path: AppRoutes.TOP_HACKS, element: <PrivateRoute component={TopHacks} /> },
  { path: AppRoutes.PASSWORD_SAFETY, element: <PrivateRoute component={PasswordSafety} /> },
  { path: AppRoutes.NEWS, element: <PrivateRoute component={News} /> },
  { path: AppRoutes.NEWS_POST, element: <PrivateRoute component={NewsPost} /> },
  { path: AppRoutes.PDF_COURSE, element: <PrivateRoute component={PdfCourse} /> },
  { path: AppRoutes.PRIVACY, element: <PrivacyPolicy /> },
  { path: AppRoutes.TERMS, element: <TermsOfUse /> },
  {
    path: AppRoutes.CANCEL_SUBSCRIPTION,
    element: <CancelSubscription />,
  },
]);

function Modals() {
  const isMobile = useMediaQuery(mediaQueries.s);

  const { isLogOutModalOpen, setLogOutModalOpen, isSWWModalOpen, setSWWModalOpen } = useAppState();

  return (
    <>
      <SomethingWentWrong isModalOpen={isSWWModalOpen} setModalOpen={setSWWModalOpen}></SomethingWentWrong>
      <LogOutModal isMobile={isMobile} isModalOpen={isLogOutModalOpen} setModalOpen={setLogOutModalOpen}></LogOutModal>
      <EmailSended />
      <LogOutModal isMobile={isMobile} isModalOpen={isLogOutModalOpen} setModalOpen={setLogOutModalOpen}></LogOutModal>
    </>
  );
}

function App() {
  usePopState();
  startLogoutTimer();

  return (
    <AppProvider>
      <Theme>
        <RouterProvider router={router} />
        <Modals />
      </Theme>
    </AppProvider>
  );
}

export default App;
