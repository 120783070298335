import { Box, Button, Typography, useTheme } from '@mui/material';
import checkmark from '../../../assets/images/unsubscribe/checkmark.svg';

function PlanUpdatedModal({ onClick }: { onClick: () => void }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#23263160',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          background: '#FFF',
          borderRadius: '20px 20px 0px 0px',
          boxShadow: '0px 5px 15px 0px rgba(106, 126, 149, 0.05)',
          padding: '30px 20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '500px',
        }}
      >
        <img width={82} src={checkmark} alt="checkmark"></img>

        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '20px',
            color: theme.palette.text.primary,
            marginTop: '20px',
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          Your plan has been successfully updated!
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Keep unlocking powerful insights and growing your Instagram, now at the price that works best for you.
        </Typography>

        <Button
          onClick={onClick}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
          }}
        >
          Great!
        </Button>
      </Box>
    </Box>
  );
}

export default PlanUpdatedModal;
