import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/index.css';
import {
  GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PDF_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
} from './utils/constants';

declare global {
  interface Window {
    subtrack_url: string;
    pdf_course_id: string;
    profile_report_id: string;
    premium_id: string;
    gift_id: string;
  }
}


// set default plan ids
window.subtrack_url = process.env.REACT_APP_SUBTRACK_URL ?? '';
window.pdf_course_id = PDF_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID;
window.profile_report_id = PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID;
window.premium_id = PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID;
window.gift_id = GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
