import { Box, Button, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import { UnsubsAnswersType } from '../UnsubscribeModal';
import { useCallback } from 'react';
import Star from '../../../assets/images/unsubscribe/star.svg';
import StarPath from '../../../assets/images/unsubscribe/star_path.svg';
import { useAppState } from '../../../providers/AppProvider.hooks';

function Step7({
  setStep,
  unsubAnswers,
  setUnsubAnswers,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  unsubAnswers: UnsubsAnswersType;
  setUnsubAnswers: React.Dispatch<React.SetStateAction<UnsubsAnswersType>>;
}) {
  const theme = useTheme();
    const { isHasGiftOneMonthSubscription } = useAppState();

  const handleContinue = useCallback(() => {
    if(isHasGiftOneMonthSubscription){
      setStep((prev) => prev + 2);
    }else{
      setStep((prev) => prev + 1);
    }
  }, [setStep, isHasGiftOneMonthSubscription]);

  return (
    <Box>
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="Your voice matters"
      />
      <Box
        sx={{
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginBottom: '10px',
          }}
        >
          Your feedback helps us create a better experience for you!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
          }}
        >
          {[1, 2, 3, 4, 5].map((el) => (
            <Box
              key={el}
              onClick={() => {
                setUnsubAnswers((prev) => ({ ...prev, stars: el }));
              }}
            >
              {el - 1 < (unsubAnswers.stars ?? 0) ? (
                <img src={Star} alt="star"></img>
              ) : (
                <img src={StarPath} alt="star"></img>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            margin: '20px 0',
            '& textarea': {
              width: '100%',
              height: '100px',
              borderRadius: '12px',
              padding: '15px',
              color: theme.palette.text.primary,
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              fontSize: '16px',
              border: '1px solid rgba(35, 38, 49, 0.10)',
              caretColor: '#256BFD',
              '&:focus-visible': {
                outline: 'none',
                border: '1px solid #256BFD',
              },
            },
            '& textarea::placeholder': {
              color: 'rgba(35, 38, 49, 0.20)',
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              fontSize: '16px',
            },
          }}
        >
          <textarea
            autoFocus
            rows={5}
            placeholder="Write your review"
            value={unsubAnswers.feedback}
            onChange={(e) => {
              setUnsubAnswers((prev) => ({ ...prev, feedback: e.target.value }));
            }}
          ></textarea>
        </Box>
        <Button
          disabled={unsubAnswers.feedback === ''}
          onClick={handleContinue}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
            '&:disabled': {
              opacity: 0.3,
              color: '#fff',
            },
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default Step7;
