import { Box, Button, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import checkmark from '../../../assets/images/unsubscribe/checkmark.svg';
import questionmark from '../../../assets/images/unsubscribe/questionmark.svg';
import { useAppState } from '../../../providers/AppProvider.hooks';
import p6 from '../../../assets/images/unsubscribe/p6.svg';
import p7 from '../../../assets/images/unsubscribe/p7.svg';
import p8 from '../../../assets/images/unsubscribe/p8.svg';
import p9 from '../../../assets/images/unsubscribe/p9.svg';
import logo from '../../../assets/images/unsubscribe/logo.webp';
import { getSubscriptionInfo } from '../../../utils/subscription';

function Step3({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const theme = useTheme();
  const { userSubscription, isHasPremiumOneMonthSubscription } = useAppState();

  const points = [
    {
      title: 'Growth Insights',
      subTitle: 'Improve your engagement',
    },
    {
      title: 'Latest IG Algorithm Secrets',
      subTitle: 'Stay ahead of the curve',
    },
    {
      title: 'Strategies for Success',
      subTitle: 'Grow your audience',
    },
    {
      title: 'Content Made Easy',
      subTitle: 'Make your posts stand out',
    },
    {
      title: 'Make Every Move Count',
      subTitle: 'Decisions that drive real results',
    },
  ];

  const userPlan = getSubscriptionInfo(userSubscription);
  const planInfo = [
    {
      title: 'Status',
      value: userPlan.status,
      img: p6,
    },
    {
      title: 'Billing period',
      value: userPlan.billingPeriod,
      img: p7,
    },
    {
      title: 'Price',
      value: `$${userPlan.price}`,
      img: p8,
    },
    {
      title: 'Extras to unlock',
      value: '3 extras',
      img: p9,
    },
  ];

  const handleChangePlanClick = () => {
    if (isHasPremiumOneMonthSubscription) {
      setStep((prev) => prev + 2);
    } else {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <Box>
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 2);
        }}
        title="Your Membership"
        bg={'#F5F5F5'}
      />
      <Box
        sx={{
          background: '#F5F5F5',
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img width={64} src={logo} alt="logo"></img>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          Current subscription plan
        </Typography>
        <Box
          sx={{
            background: '#fff',
            padding: '15px 20px',
            borderRadius: '24px',
            width: '100%',
          }}
        >
          {planInfo.map((el, i, arr) => (
            <Box key={i}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '15px',
                  alignItems: 'center',
                }}
              >
                <img src={el.img} alt="point"></img>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir Next',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      fontSize: '14px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir Next',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      fontSize: '16px',
                      color: theme.palette.text.primary,
                    }}
                  >
                    {el.value}
                  </Typography>
                </Box>
              </Box>
              {i < arr.length - 1 && (
                <Box
                  sx={{
                    height: '1px',
                    background: '#232631',
                    opacity: 0.1,
                    margin: '10px 0 10px 39px',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          padding: '40px 20px 0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: '16px',
            background: 'linear-gradient(270deg, #FF9A84 0%, #EF5DCE 50%, #2B42DB 100%)',
            padding: '8px 15px 8px 15px',
            fontFamily: 'Avenir Next',
            fontWeight: 700,
            lineHeight: 'normal',
            fontSize: '10px',
            textTransform: 'uppercase',
            color: '#FFF',
            width: 'max-content',
          }}
        >
          Hold onto your benefits
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginTop: '15px',
          }}
        >
          What's included in your premium membership?
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: '#F63C47',
            marginTop: '5px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Don't loose these game-changing insights to level up your Instagram strategy
        </Typography>
        <Box
          sx={{
            background: '#F5F5F5',
            borderRadius: '20px',
            width: '100%',
            padding: '20px',
            marginBottom: '20px',
          }}
        >
          {points.map((el, i) => (
            <Box key={i}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir Next',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      fontSize: '16px',
                      color: theme.palette.text.primary,
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir Next',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      fontSize: '14px',
                      color: theme.palette.text.secondary,
                    }}
                  >
                    {el.subTitle}
                  </Typography>
                </Box>
                <img src={checkmark} alt="checkmark"></img>
              </Box>
              <Box
                sx={{
                  height: '1px',
                  background: '#232631',
                  opacity: 0.1,
                  margin: '15px 0',
                }}
              />
            </Box>
          ))}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontWeight: 400,
                lineHeight: 'normal',
                fontSize: '16px',
                color: '#F63C47',
                span: {
                  fontWeight: 600,
                },
              }}
            >
              Are you sure you want to <span>lose all</span> this premium features?
            </Typography>
            <img src={questionmark} alt="questionmark"></img>
          </Box>
        </Box>
        <Button
          onClick={handleChangePlanClick}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
          }}
        >
          Change my plan
        </Button>
        <Typography
          onClick={() => {
            setStep((prev) => prev + 2);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontWeight: 400,
            fontFamily: 'Avenir Next',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          Cancel membership
        </Typography>
      </Box>
    </Box>
  );
}

export default Step3;
