import PageLayout from '../../components/PageLayout';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ArrowIcon from '../../../../assets/svg/arrow-left.svg';
import useScrollTop from '../../../../hooks/useScrollTop';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../utils/constants';

export default function TopTrends() {
  useScrollTop();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <PageLayout title="Trend of 2025">
      <Box
        sx={{
          padding: '30px 20px 0',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: 'normal',
            textAlign: 'left',
            color: theme.palette.text.primary,
          }}
        >
          Top 7 Instagram Trends That Will Define 2025
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'left',
            margin: '20px 0',
            color: theme.palette.text.primary,
          }}
        >
          Instagram is evolving fast, and sticking to old strategies won’t cut it in 2025. With shifting algorithms, new
          content formats, and changes in user behavior, creators and brands need to adapt NOW to stay ahead. Here are
          the 7 biggest Instagram trends you must watch in 2025.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            '& .wrapper': {
              borderRadius: '16px',
              background: '#F5F5F5',
              padding: '30px 20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              '& .title': {
                fontFamily: 'Avenir Next',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
              },
              '& .subTitle': {
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
                span: {
                  fontWeight: 600,
                },
              },
              '& .pointTitle': {
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
              },
              '& .point': {
                position: 'relative',
                paddingLeft: '23px',
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
                span: {
                  fontWeight: 600,
                },
                '&:before': {
                  position: 'absolute',
                  top: 6,
                  left: 0,
                  content: '""',
                  width: '8px',
                  height: '8px',
                  background: '#56AC14',
                  borderRadius: '50%',
                },
              },
            },
          }}
        >
          <Box className="wrapper">
            <Typography className="title">Trend 1: “Edutainment” Will Be the Content Formula for Success</Typography>
            <Typography className="subTitle">
              People don’t just want to be entertained - they want to learn, too. Instagram’s most successful creators
              in 2025 will be those who blend education with entertainment (“edutainment”). Think: valuable insights
              delivered in a fast, fun, engaging way.
            </Typography>
            <Typography className="pointTitle">How to Win:</Typography>
            <Typography className="point">
              Turn <span>expertise into snackable</span> content (Example: “3 Instagram hacks you NEED in 2025 👀🔥”)
            </Typography>
            <Typography className="point">
              Use <span>storytelling & humor</span> to deliver valuable lessons.
            </Typography>
            <Typography className="point">
              Repurpose educational content into <span>Reels, Carousels & Stories.</span>
            </Typography>
            <Typography className="subTitle">
              <span>The Takeaway:</span> If you’re not teaching AND entertaining, you’re losing out on engagement.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Trend 2: AI-Generated Content Becomes the Norm</Typography>
            <Typography className="subTitle">
              AI is reshaping Instagram, from automated captions to AI-powered visuals and smart content
              recommendations. Creators using AI will gain an edge.
            </Typography>
            <Typography className="pointTitle">How to Win:</Typography>
            <Typography className="point">
              Use AI tools like <span>ChatGPT or Jasper</span> to generate viral post ideas.
            </Typography>
            <Typography className="point">
              Try <span>AI-driven visuals</span> (e.g., MidJourney, Canva AI) to create eye-catching content.
            </Typography>
            <Typography className="point">
              Automate <span>posting schedules</span> with AI-based tools.
            </Typography>
            <Typography className="subTitle">
              <span>The Takeaway:</span> AI won’t replace creators - but it will replace those who don’t use it.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Trend 3: Private Communities &gt; Mass Following</Typography>
            <Typography className="subTitle">
              Instagram is shifting from mass audiences to niche, engaged communities. Close Friends, Broadcast
              Channels, and Subscriptions are exploding in popularity.
            </Typography>
            <Typography className="pointTitle">How to Win:</Typography>
            <Typography className="point">
              Build a <span>Close Friends list</span> for exclusive behind-the-scenes content.
            </Typography>
            <Typography className="point">
              Start a <span>Broadcast Channel</span> for direct access to your biggest fans.
            </Typography>
            <Typography className="point">
              Offer <span>paid Subscriptions</span> to create premium community content.
            </Typography>
            <Typography className="subTitle">
              <span>The Takeaway:</span> AA tight-knit, engaged community is more valuable than millions of passive
              followers.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">
              Trend 4: Instagram Becomes a Search Engine (Instagram SEO is King)
            </Typography>
            <Typography className="subTitle">
              Instagram is shifting toward search-based discovery, meaning keywords, not just hashtags, will determine
              whether your content gets found. Users are searching Instagram like Google, and posts optimized for
              SEO-driven captions are ranking higher.
            </Typography>
            <Typography className="pointTitle">How to Win:</Typography>
            <Typography className="point">
              Write <span>keyword-rich captions</span> (Example: Instead of just “☕ Morning vibes! #coffeelover” → try
              “Best morning coffee spots in NYC ☕✨ Save this for your next coffee run!”)
            </Typography>
            <Typography className="point">
              Use <span>searchable keywords</span> in your bio (e.g., "Skincare Expert | Acne & Glow Tips")
            </Typography>
            <Typography className="point">
              Add <span>alt text</span> to images to boost discoverability in IG search.
            </Typography>
            <Typography className="subTitle">
              <span>The Takeaway:</span> If you’re not optimizing your content for search, you’re missing out on organic
              reach.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">
              Trend 5: Reels Are Still King - But It’s All About “Ultra-Short” Videos
            </Typography>
            <Typography className="subTitle">
              The TikTok effect is real, and Instagram is favoring shorter Reels with higher watch retention. In 2025,
              5-7 second Reels will outperform longer videos.
            </Typography>
            <Typography className="pointTitle">How to Win:</Typography>
            <Typography className="point">
              Keep Reels <span>under 7 seconds</span> to maximize loop plays
            </Typography>
            <Typography className="point">
              Use fast hooks & engaging visuals in the <span>first 3 seconds</span>
            </Typography>
            <Typography className="point">
              <span>Text overlays & captions</span> keep viewers locked in, even without sound
            </Typography>
            <Typography className="subTitle">
              <span>The Takeaway:</span> Short, high-retention Reels will dominate feeds. Master them now!
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Trend 6: Long-Form Carousels Will Rival Reels</Typography>
            <Typography className="subTitle">
              While Reels are still king, carousel posts with in-depth, storytelling-style content will explode in
              engagement in 2025. Instagram is prioritizing saves & shares, and carousels encourage both.
            </Typography>
            <Typography className="pointTitle">How to Win:</Typography>
            <Typography className="point">
              Create <span>step-by-step carousels</span> (Example: “How to go viral in 2025 🚀 [Swipe to learn!]”)
            </Typography>
            <Typography className="point">
              Use <span>bold, readable text</span> and make the first slide a hook that stops the scroll
            </Typography>
            <Typography className="point">
              End with a <span>“Save this post!” CTA</span> to boost reach.
            </Typography>
            <Typography className="subTitle">
              <span>The Takeaway:</span> Carousels aren’t dead - they’re making a comeback as the new ‘Instagram blog.’
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Trend 7: Instagram Shopping 2.0: Social Commerce Takes Over</Typography>
            <Typography className="subTitle">
              Instagram isn’t just for content anymore - it’s a shopping platform. With the rise of social commerce,
              brands & creators are monetizing through Shoppable posts, affiliate links, and direct checkout.
            </Typography>
            <Typography className="pointTitle">How to Win:</Typography>
            <Typography className="point">
              Use <span>Shoppable Tags</span> on posts to make buying seamless
            </Typography>
            <Typography className="point">
              Start <span>Live Shopping</span> sessions to sell products in real-time
            </Typography>
            <Typography className="point">
              Share <span>affiliate links</span> for commission-based monetization
            </Typography>
            <Typography className="subTitle">
              <span>The Takeaway:</span> If you’re not selling on Instagram in 2025, you’re leaving money on the table.
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'left',
            margin: '20px 0',
            color: theme.palette.text.primary,
          }}
        >
          The game is changing fast—what worked in 2023-24 won’t cut it anymore. The smartest creators & brands are
          already adapting - are you? Stay ahead, evolve your strategy, and dominate Instagram in 2025!
        </Typography>

        <Button
          sx={{
            position: 'relative',
            margin: '20px 0 0',
            width: '100%',
            padding: '19px',
            background: theme.palette.grey['300'],
            fontFamily: 'Avenir Next',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '27.32px',
            textAlign: 'center',
            borderRadius: '12px',
            textTransform: 'initial',
            color: theme.palette.text.primary,
            '&:hover': {
              background: theme.palette.grey['300'],
            },
            '&:before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '15px',
              transform: 'translateY(-50%)',
              display: 'block',
              width: '24px',
              height: '24px',
              backgroundImage: `url(${ArrowIcon})`,
            },
          }}
          onClick={() => {
            navigate(AppRoutes.DASHBOARD);
          }}
        >
          To all articles
        </Button>
      </Box>
    </PageLayout>
  );
}
