import { Box, Button, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import { useAppState } from '../../../providers/AppProvider.hooks';
import { UnsubsAnswersType } from '../UnsubscribeModal';
import { useCallback } from 'react';
import CheckMarkSvg from '../../../assets/svg/check-mark.svg';

const answers = [
  'Forget it - I want to keep my membership',
  'Not using It enough',
  'Not seeing immediate results',
  'My social media priorities changed',
  'I only wanted a trial',
  "I'm cutting down on expenses",
  'Looking for a free alternative',
  'Other',
];

function Step6({
  setStep,
  unsubAnswers,
  setUnsubAnswers,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  unsubAnswers: UnsubsAnswersType;
  setUnsubAnswers: React.Dispatch<React.SetStateAction<UnsubsAnswersType>>;
}) {
  const theme = useTheme();
  const { setUnsubscribeOpen } = useAppState();

  const handleContinue = useCallback(() => {
    if (unsubAnswers.select === answers[0]) {
      setUnsubscribeOpen(false);
    } else {
      setStep((prev) => prev + 1);
    }
  }, [unsubAnswers, setStep, setUnsubscribeOpen]);

  return (
    <Box>
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="Your Membership"
      />
      <Box
        sx={{
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginBottom: '5px',
          }}
        >
          What made you change your mind about the membership?
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '100%',
            margin: '30px 0',
          }}
        >
          {answers.map((el, i) => (
            <Box
              onClick={() => {
                setUnsubAnswers((prev) => ({ ...prev, select: el }));
              }}
              key={i}
              sx={{
                WebkitTapHighlightColor: 'transparent',
                cursor: 'pointer',
                height: '60px',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
                outline: el === unsubAnswers.select ? undefined : '1px solid rgba(35, 38, 49, 0.20)',
                background: el === unsubAnswers.select ? 'rgba(37, 107, 253, 0.10)' : undefined,
                padding: '0 20px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir Next',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  fontSize: '16px',
                  color: theme.palette.text.primary,
                }}
              >
                {el}
              </Typography>
              {el === unsubAnswers.select ? (
                <img width={24} src={CheckMarkSvg} alt="checked"></img>
              ) : (
                <Box
                  sx={{
                    border: '1px solid rgba(35, 38, 49, 0.20)',
                    borderRadius: '50%',
                    width: '24px',
                    height: '24px',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Button
          disabled={unsubAnswers.select === null}
          onClick={handleContinue}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
            '&:disabled': {
              opacity: 0.3,
              color: '#fff',
            },
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
}

export default Step6;
