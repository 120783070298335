import { SubscribeRecurlySuccess, SubscribeRecurlyError, UserSubscription } from '../services/requests';

export const isSuccessfullySubscriptionResponse = (response: any): response is SubscribeRecurlySuccess => {
  return typeof response.id === 'string';
};

export const isErrorSubscriptionResponse = (response: any): response is SubscribeRecurlyError => {
  return typeof response.error === 'string';
};

export const getSubscriptionInfo = (subscription: UserSubscription | null) => {
  switch (true) {
    case subscription?.plan_code.includes('premium_one_month'): {
      return {
        name: 'Premium Plan',
        price: 13.99,
        status: 'Premium',
        billingPeriod: 'Every 1 month',
      };
    }
    case subscription?.plan_code.includes('gift_one_month'): {
      return {
        name: 'Gift Plan',
        price: 19.99,
        status: 'Gift',
        billingPeriod: 'Every 1 month',
      };
    }
    case subscription?.plan_code.includes('3month'): {
      return {
        name: '3-Month Plan',
        price: 49,
        status: 'Premium',
        billingPeriod: 'Every 12 weeks',
      };
    }
    default: {
      return {
        name: 'Weekly Plan',
        price: 1,
        status: 'Trial',
        billingPeriod: 'Every week',
      };
    }
  }
};
