import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import discount from '../../../assets/images/unsubscribe/discount_icon.svg';
import chart from '../../../assets/images/unsubscribe/chart.svg';
import shield from '../../../assets/images/unsubscribe/shield_icon.svg';
import fire from '../../../assets/images/unsubscribe/fire.svg';
import { useState } from 'react';
import { useAppState } from '../../../providers/AppProvider.hooks';
import { createSubscription } from '../../../services/requests';
import PlanUpdatedModal from '../components/PlanUpdatedModal';

function Step4({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const theme = useTheme();
  const [isUpdated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { firebaseUserCredential, checkUserSubscription, setUnsubscribeOpen, subscriptionId } = useAppState();

  const plan = {
    id: window.premium_id,
    discount: 90,
    price: 13.99,
    full_price: 139.99,
  };

  const handleChangePlan = () => {
    if (loading) return;

    if (firebaseUserCredential && firebaseUserCredential.email && subscriptionId) {
      setLoading(true);
      createSubscription({
        plan_id: plan.id,
        email: firebaseUserCredential.email,
        firebase_id: firebaseUserCredential.uid,
        mainPlanId: subscriptionId,
      })
        .then(async (response) => {
          if ('error' in response) {
            setError(response.error);
          }
          if ('id' in response) {
            setUpdated(true);
            await checkUserSubscription(firebaseUserCredential.uid);
          }
        })
        .catch(async (error) => {
          let parsedErrorMessage = '';
          try {
            const errorJson = await error.json();
            if ('error' in errorJson) {
              parsedErrorMessage = errorJson.error;
            }
          } catch (err) {
            console.log(err);
          }
          const errorMessage = parsedErrorMessage !== '' ? parsedErrorMessage : error;
          setError(JSON.stringify(errorMessage));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box>
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="Change my plan"
        bg={'#F5F5F5'}
      />
      <Box
        sx={{
          background: 'rgba(86, 172, 20, 0.10)',
          padding: '15px 20px',
          display: 'flex',
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <img src={discount} alt="discount"></img>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '14px',
            color: theme.palette.text.primary,
            span: {
              color: '#56B200',
            },
          }}
        >
          <span>Additional {plan.discount}% discount</span> has been applied just for you!
        </Typography>
      </Box>
      <Box
        sx={{
          padding: '30px 20px 40px',
          background: '#F5F5F5',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            span: {
              color: '#56B200',
            },
          }}
        >
          Unlock premium insights and growth tools <span>saving {plan.discount}%!</span>
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginTop: '5px',
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Don’t miss your chance to boost your Instagram strategy with all premium features at an unbeatable price.
        </Typography>
        <Box
          sx={{
            background: '#FFF',
            borderRadius: '16px',
            padding: '15px 20px',
          }}
        >
          <img src={chart} alt="chart"></img>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              lineHeight: 'normal',
              fontSize: '12px',
              color: theme.palette.text.secondary,
              marginTop: '15px',
              textAlign: 'center',
              span: {
                fontWeight: 600,
                color: theme.palette.text.primary,
              },
            }}
          >
            Premium users who use our strategies grow <span>1.5x faster</span>, based on our research.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            margin: '20px 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontWeight: 400,
                lineHeight: 'normal',
                fontSize: '14px',
                color: theme.palette.text.primary,
              }}
            >
              One-time price offer:
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontWeight: 600,
                lineHeight: 'normal',
                fontSize: '14px',
                color: theme.palette.text.primary,
              }}
            >
              ${plan.price}
            </Typography>
          </Box>
          <Box
            sx={{
              height: '1px',
              width: '100%',
              background: '#232631',
              opacity: 0.1,
              margin: '5px 0',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontWeight: 400,
                lineHeight: 'normal',
                fontSize: '14px',
                color: theme.palette.text.primary,
              }}
            >
              Full price:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Typography
                sx={{
                  padding: '3px 5px',
                  display: 'flex',
                  gap: '4px',
                  fontFamily: 'Avenir Next',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  fontSize: '14px',
                  color: '#F63C47',
                  background: 'rgba(246, 60, 71, 0.10)',
                  borderRadius: '20px',
                }}
              >
                <img src={fire} alt="fire"></img>
                SAVE {plan.discount}%
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Avenir Next',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  fontSize: '14px',
                  color: '#F63C47',
                  textDecoration: 'line-through',
                }}
              >
                ${plan.full_price}
              </Typography>
            </Box>
          </Box>
        </Box>
        {error !== '' && (
          <Box
            sx={{
              textAlign: 'center',
              width: '100%',
              paddingBottom: '15px',
              span: {
                color: '#E53E3E',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 'normal',
              },
            }}
          >
            <span>{error}</span>
          </Box>
        )}
        <Button
          onClick={() => {
            handleChangePlan();
          }}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
          }}
        >
          {loading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
              }}
            />
          ) : (
            'Change my plan'
          )}
        </Button>
      </Box>
      <Box
        sx={{
          padding: '20px',
        }}
      >
        <Box
          sx={{
            borderRadius: '20px',
            border: '1px solid #E6E7EB',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              padding: '10px 20px',
              background: '#F5F5F5',
              borderRadius: '20px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              fontFamily: 'Avenir Next',
              fontWeight: 600,
              lineHeight: 'normal',
              fontSize: '14px',
              color: theme.palette.text.primary,
            }}
          >
            <img src={shield} alt="shield"></img>
            100% money back guarantee
          </Box>
          <Typography
            sx={{
              fontFamily: 'Avenir Next',
              fontWeight: 400,
              lineHeight: 'normal',
              fontSize: '12px',
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            Your Premium TrustCheck plan costs ${plan.price}/month. By tapping "Change my plan," you agree to a monthly
            subscription with automatic billing.
          </Typography>
        </Box>
      </Box>

      {isUpdated && (
        <PlanUpdatedModal
          onClick={() => {
            setUnsubscribeOpen(false);
          }}
        />
      )}
    </Box>
  );
}

export default Step4;
