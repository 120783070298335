import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { AppProviderContextType } from './AppProvider.types';

import { auth, signIn } from '../services/firebase';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import {
  fetchNews,
  getUserSubscriptions,
  INSIGHTS_STATUS,
  InstagramUserData,
  NewsItem,
  sendActivationByEmail,
  sendPDFReportData,
  UserBreachedSitesResponse,
  UserSubscription,
} from '../services/requests';
import {
  AppRoutes,
  GIFT_NEW_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS,
  GIFT_TURBO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PDF_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PDF_COURSE_SUBSCRIPTION_PLAN_IDS,
  PDF_NEW_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PDF_TURBO_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PREMIUM_NEW_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS,
  PREMIUM_TURBO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PROFILE_NEW_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
  PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_IDS,
  PROFILE_TURBO_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
} from '../utils/constants';
import { resolveUserCredentials } from '../utils/credentials';
import logger from '../utils/logger';
import firebaseAuthErrors from '../constants/firebase-auth-errors';
import { Nullable } from '../models/common';

export const AppContext = createContext<AppProviderContextType | null>(null);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLogOutModalOpen, setLogOutModalOpen] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(true);

  const [isSWWModalOpen, setSWWModalOpen] = useState(false);

  const [isPdfCoursePurchaseModalOpen, setPdfCoursePurchaseModalOpen] = useState(false);

  const [isShowProcessInsightsModal, setIsShowProcessingInsightsModal] = useState(false);

  const [isSignInLoading, setSignInLoading] = useState(false);

  const [isUserAuthorized, setUserAuthorized] = useState(false);

  const [isHasPdfCourseSubscription, setHasPdfCourseSubscription] = useState(false);

  const [isHasProfileReportSubscription, setHasProfileReportSubscription] = useState(false);

  const [isHasPremiumOneMonthSubscription, setHasPremiumOneMonthSubscription] = useState(false);

  const [isHasGiftOneMonthSubscription, setHasGiftOneMonthSubscription] = useState(false);

  const [isBoostgram, setBoostgram] = useState<boolean>(document.location.hostname.includes('gramsmetrics.com'));

  const [subscriptionId, setSubscriptionId] = useState<Nullable<string>>(null);

  const [subscriptionStartedAt, setSubscriptionStartedAt] = useState<Nullable<string>>(null);

  const [userSubscription, setUserSubscription] = useState<Nullable<UserSubscription>>(null);

  const [isSubscriptionCanceled, setSubscriptionCanceled] = useState(false);

  const [isUnsubscribeEmailSent, setUnsubscribeEmailSent] = useState(false);

  const [instagramUserData, setInstagramUserData] = useState<InstagramUserData | null>(null);

  const [news, setNews] = useState<Array<NewsItem> | null>(null);

  const [firebaseUserCredential, setFirebaseUserCredential] = useState<User | null>(null);

  const signInCredentials = useMemo(
    () => resolveUserCredentials(new URLSearchParams(window.location.search).get('creds')),
    []
  );

  const [authenticationErrorMessage, setAuthenticationErrorMessage] = useState('');

  const [activationEmailSended, setActivationEmailSended] = useState<string | null>(null);

  const [userBreachedSites, setUserBreachedSites] = useState<Array<UserBreachedSitesResponse> | null>(null);

  const [isBreachedSitesInfoFetched, setBreachedSitesInfoFetched] = useState(false);

  const [isUnsubscribeOpen, setUnsubscribeOpen] = useState(false);

  const [isManageSubscriptionsOpen, setManageSubscriptionsOpen] = useState(false);

  const checkUserSubscription = async (token: string) => {
    setSignInLoading(true);

    getUserSubscriptions({ token })
      .then(async (response) => {
        const isHasPdfCourseSubscription = response.subscriptions.find((subscription) => {
          return (
            subscription.bundle === 'com.huynh.socialmetric' &&
            PDF_COURSE_SUBSCRIPTION_PLAN_IDS.includes(subscription.plan_code) &&
            subscription.status !== 'expired'
          );
        });
        if (isHasPdfCourseSubscription) {
          setHasPdfCourseSubscription(true);
        }
        const isHasProfileReportSubscription = response.subscriptions.find((subscription) => {
          return (
            subscription.bundle === 'com.huynh.socialmetric' &&
            PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_IDS.includes(subscription.plan_code) &&
            subscription.status !== 'expired'
          );
        });
        if (isHasProfileReportSubscription) {
          setHasProfileReportSubscription(true);
        }

        const isHasPremiumOneMonthSubscription = response.subscriptions.find((subscription) => {
          return (
            subscription.bundle === 'com.huynh.socialmetric' &&
            PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS.includes(subscription.plan_code) &&
            subscription.status !== 'expired'
          );
        });
        if (isHasPremiumOneMonthSubscription) {
          setHasPremiumOneMonthSubscription(true);
        }

        const isHasGiftOneMonthSubscription = response.subscriptions.find((subscription) => {
          return (
            subscription.bundle === 'com.huynh.socialmetric' &&
            GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_IDS.includes(subscription.plan_code) &&
            subscription.status !== 'expired'
          );
        });
        if (isHasGiftOneMonthSubscription) {
          setHasGiftOneMonthSubscription(true);
        }

        const userSubscription = response.subscriptions.find((subscription) => {
          return (
            subscription.bundle === 'com.huynh.socialmetric' &&
            ['active', 'canceled'].includes(subscription.status) &&
            subscription.main_plan
          );
        });

        if (userSubscription) {
          setUserAuthorized(true);
          setUserSubscription(userSubscription);
          setBoostgram(userSubscription.plan_code.includes('_x_'));
          setSubscriptionId(userSubscription.id);
          setSubscriptionStartedAt(userSubscription.started_at);
          setSubscriptionCanceled(userSubscription.status === 'canceled');
          setSWWModalOpen(false);
          const getSubtrackValues = () => {
            switch (true) {
              case userSubscription.plan_code.includes('_x_'): {
                return {
                  url: process.env.REACT_APP_NEW_SUBTRACK_URL,
                  pdf_course_id: PDF_NEW_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  profile_report_id: PROFILE_NEW_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  premium_id: PREMIUM_NEW_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  gift_id: GIFT_NEW_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
                };
              }
              case userSubscription.plan_code.includes('_y_'): {
                return {
                  url: process.env.REACT_APP_TURBOGRAM_SUBTRACK_URL,
                  pdf_course_id: PDF_TURBO_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  profile_report_id: PROFILE_TURBO_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  premium_id: PREMIUM_TURBO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  gift_id: GIFT_TURBO_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
                };
              }
              default: {
                return {
                  url: process.env.REACT_APP_SUBTRACK_URL,
                  pdf_course_id: PDF_COURSE_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  profile_report_id: PROFILE_REPORT_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  premium_id: PREMIUM_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
                  gift_id: GIFT_ONE_MONTH_PURCHASE_SUBSCRIPTION_PLAN_ID,
                };
              }
            }
          };

          const values = getSubtrackValues() ?? '';
          // console.log({values});
          window.subtrack_url = values.url ?? '';
          window.pdf_course_id = values.pdf_course_id;
          window.profile_report_id = values.profile_report_id;
          window.premium_id = values.premium_id;
          window.gift_id = values.gift_id;
        } else {
          await logger(
            new URLSearchParams({
              source: 'check_active_subscription',
              creds: JSON.stringify(signInCredentials),
              subscriptions: JSON.stringify(response.subscriptions),
            })
          );

          setSWWModalOpen(true);
        }

        setSignInLoading(false);
      })
      .catch((error) => {
        console.error('getUserSubscriptions error', error);
        setSignInLoading(false);
      });
  };

  const handleLogIn = async (email: string, password: string) => {
    setSignInLoading(true);
    setAuthenticationErrorMessage('');

    signIn(email, password)
      .then(async (userCredential) => {
        console.log('sign In userCredential', userCredential);
        if (userCredential?.user) {
          checkUserSubscription(userCredential.user.uid);
          setFirebaseUserCredential(userCredential.user);
        } else {
          setSignInLoading(false);

          await logger(
            new URLSearchParams({
              source: 'sign_in',
              message: 'user_is_not_found',
            })
          );
        }
      })
      .catch(async (err) => {
        console.log('sign In Error', err);

        setSignInLoading(false);

        const authErrorMessage = err.code
          ? firebaseAuthErrors[err.code as keyof typeof firebaseAuthErrors]
          : 'Something went wrong';

        setAuthenticationErrorMessage(authErrorMessage);

        await logger(
          new URLSearchParams({
            source: 'sign_in_error',
            message: String(authErrorMessage),
            data: JSON.stringify({ email, password }),
          })
        );

        console.log('send activation', Date.now());
        sendActivationByEmail({ email })
          .then((resp) => {
            if (resp.success) {
              setActivationEmailSended(email);
            }
          })
          .catch((err) => {
            console.error('sendActivationByEmail err', err);
          });
      });
  };

  useEffect(() => {
    if (signInCredentials) {
      handleLogIn(signInCredentials.email, signInCredentials.password);
    }
  }, [signInCredentials]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      const isPostponeSubscriptionCheck = window.location.href.includes(AppRoutes.ACCESS_RECOVERY);

      if (!user) {
        setUserAuthorized(false);
      } else {
        if (isPostponeSubscriptionCheck) {
          setTimeout(() => {
            checkUserSubscription(user.uid);
            setFirebaseUserCredential(user);
          }, 4000);
        } else {
          checkUserSubscription(user.uid);
          setFirebaseUserCredential(user);
        }
      }
    });
  }, []);

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // Remove login timestamp
        window.localStorage.removeItem('loginTimestamp');

        // Clear brached sites info
        setBreachedSitesInfoFetched(false);
        setUserBreachedSites(null);
      })
      .catch((error) => {
        // An error happened.
      })
      .finally(() => {
        setLogOutModalOpen(false);
      });
  };

  useEffect(() => {
    fetchNews()
      .then((response) => {
        setNews(response.news);
      })
      .catch((error) => {
        console.error('fetchNews error', error);
      });
  }, []);

  const handleSendPDFReportData = () => {
    if (firebaseUserCredential !== null) {
      return sendPDFReportData({
        email: firebaseUserCredential.email,
        uid: firebaseUserCredential.uid,
      });
    }
  };

  useEffect(() => {
    const isUnsubscribe = new URLSearchParams(window.location.search).get('unsubscription') === 'true';
    setUnsubscribeOpen(isUnsubscribe);
  }, []);

  const value: AppProviderContextType = {
    firebaseUserCredential,
    isBoostgram,
    subscriptionId,
    setIsShowProcessingInsightsModal,
    isShowProcessInsightsModal,
    authenticationErrorMessage,
    handleLogIn,
    handleLogOut,
    isLogOutModalOpen,
    setLogOutModalOpen,
    isSignInLoading,
    setSignInLoading,
    isUserAuthorized,
    isSWWModalOpen,
    setSWWModalOpen,
    instagramUserData,
    setInstagramUserData,
    showUpdateModal,
    setShowUpdateModal,
    handleSendPDFReportData,
    isHasPdfCourseSubscription,
    isPendingInsights: instagramUserData?._insights?.status === INSIGHTS_STATUS.PENDING,
    isProcessingInsights: instagramUserData?._insights?.status === INSIGHTS_STATUS.PROCESSING,
    isCompletedInsights: instagramUserData?._insights?.status === INSIGHTS_STATUS.COMPLETED,
    isPdfCoursePurchaseModalOpen,
    setPdfCoursePurchaseModalOpen,
    checkUserSubscription,
    isSubscriptionCanceled,
    setSubscriptionCanceled,
    isUnsubscribeEmailSent,
    setUnsubscribeEmailSent,
    activationEmailSended,
    setActivationEmailSended,
    isHasProfileReportSubscription,
    isHasGiftOneMonthSubscription,
    isHasPremiumOneMonthSubscription,
    subscriptionStartedAt,
    userSubscription,
    news,
    userBreachedSites,
    setUserBreachedSites,
    isBreachedSitesInfoFetched,
    setBreachedSitesInfoFetched,
    isUnsubscribeOpen,
    setUnsubscribeOpen,
    isManageSubscriptionsOpen,
    setManageSubscriptionsOpen,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
