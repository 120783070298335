import { Box, Typography } from '@mui/material';
import ArrowIcon from '../../../assets/svg/arrow-narrow-left.svg';

function StepHeader({ title, onClick, bg }: { title: string; onClick: () => void, bg?: string }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        height: '57px',
        borderBottom: '1px solid #23263110',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        background: bg ?? '#fff',
      }}
    >
      <img
        style={{
          position: 'absolute',
          left: '20px',
        }}
        src={ArrowIcon}
        alt="arrow"
      ></img>
      <Typography
        sx={{
          fontFamily: 'Avenir Next',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: 'normal',
          color: '#232631',
          userSelect: 'none',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export default StepHeader;
