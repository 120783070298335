import { Box, Dialog } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppState } from '../../providers/AppProvider.hooks';
import Layout from '../Dashboard/components/Layout';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import Step7 from './steps/Step7';
import Step8 from './steps/Step8';
import Step9 from './steps/Step9';

export type UnsubsAnswersType = {
  select: string | null;
  stars: number | null;
  feedback: string;
};

function UnsubscribeModal() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState(0);
  const { isUnsubscribeOpen, isHasGiftOneMonthSubscription, isHasPremiumOneMonthSubscription } = useAppState();
  console.log('isHasGiftOneMonthSubscription',isHasGiftOneMonthSubscription);
  console.log('isHasPremiumOneMonthSubscription',isHasPremiumOneMonthSubscription);
  const [unsubAnswers, setUnsubAnswers] = useState<UnsubsAnswersType>({
    select: null,
    stars: null,
    feedback: '',
  });

  useEffect(() => {
    wrapperRef.current?.scrollIntoView();
  }, [step]);

  const steps = useMemo(() => {
    return [
      <Step1 setStep={setStep} />,
      <Step2 setStep={setStep} />,
      <Step3 setStep={setStep} />,
      <Step4 setStep={setStep} />,
      <Step5 setStep={setStep} />,
      <Step6 setStep={setStep} unsubAnswers={unsubAnswers} setUnsubAnswers={setUnsubAnswers} />,
      <Step7 setStep={setStep} unsubAnswers={unsubAnswers} setUnsubAnswers={setUnsubAnswers} />,
      <Step8 setStep={setStep} />,
      <Step9 setStep={setStep} />,
    ];
  }, [unsubAnswers]);

  const renderStep = useMemo(() => {
    return steps[step];
  }, [steps, step]);

  return (
    <Dialog fullScreen open={isUnsubscribeOpen}>
      <Layout>
        <Box
          ref={wrapperRef}
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          {renderStep}
        </Box>
      </Layout>
    </Dialog>
  );
}

export default UnsubscribeModal;
