import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import Offer from '../../../assets/images/unsubscribe/offer.webp';
import p10 from '../../../assets/images/unsubscribe/p10.svg';
import p11 from '../../../assets/images/unsubscribe/p11.svg';
import p12 from '../../../assets/images/unsubscribe/p12.svg';
import { useState } from 'react';
import { useAppState } from '../../../providers/AppProvider.hooks';
import { createSubscription } from '../../../services/requests';
import PlanUpdatedModal from '../components/PlanUpdatedModal';

function Step8({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const [isUpdated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { firebaseUserCredential, checkUserSubscription, setUnsubscribeOpen, subscriptionId } = useAppState();

  const theme = useTheme();
  const points = [
    {
      title: 'AI-Powered Growth Plans',
      img: p10,
    },
    {
      title: 'Trending Hashtag Finder',
      img: p11,
    },
    {
      title: 'Advanced Content Scoring',
      img: p12,
    },
  ];

  const handleChangePlan = () => {
    if (loading) return;

    if (firebaseUserCredential && firebaseUserCredential.email && subscriptionId) {
      setLoading(true);
      createSubscription({
        plan_id: window.gift_id,
        email: firebaseUserCredential.email,
        firebase_id: firebaseUserCredential.uid,
        mainPlanId: subscriptionId,
      })
        .then(async (response) => {
          if ('error' in response) {
            setError(response.error);
          }
          if ('id' in response) {
            setUpdated(true);
            await checkUserSubscription(firebaseUserCredential.uid);
          }
        })
        .catch(async (error) => {
          let parsedErrorMessage = '';
          try {
            const errorJson = await error.json();
            if ('error' in errorJson) {
              parsedErrorMessage = errorJson.error;
            }
          } catch (err) {
            console.log(err);
          }
          const errorMessage = parsedErrorMessage !== '' ? parsedErrorMessage : error;
          setError(JSON.stringify(errorMessage));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box>
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        title="One last thing"
      />
      <Box
        sx={{
          padding: '20px 20px 0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            img: {
              width: '100%',
            },
          }}
        >
          <img src={Offer} alt="Offer"></img>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginTop: '20px',
            marginBottom: '5px',
          }}
        >
          Exciting new features are coming!
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            textAlign: 'center',
          }}
        >
          Keep unlocking powerful insights to grow your Instagram profile:
        </Typography>
        <Box
          sx={{
            background: '#F5F5F5',
            padding: '20px',
            borderRadius: '20px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            margin: '20px 0',
          }}
        >
          {points.map((el, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
              }}
            >
              <img width={24} src={el.img} alt="point"></img>
              <Typography
                sx={{
                  fontFamily: 'Avenir Next',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  fontSize: '16px',
                  color: theme.palette.text.primary,
                }}
              >
                {el.title}
              </Typography>
            </Box>
          ))}
        </Box>
        {error !== '' && (
          <Box
            sx={{
              textAlign: 'center',
              width: '100%',
              paddingBottom: '15px',
              span: {
                color: '#E53E3E',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 'normal',
              },
            }}
          >
            <span>{error}</span>
          </Box>
        )}
        <Button
          onClick={handleChangePlan}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
          }}
        >
          {loading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#fff',
              }}
            />
          ) : (
            'Get my free month'
          )}
        </Button>
        <Typography
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontWeight: 400,
            fontFamily: 'Avenir Next',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          I'm not interested
        </Typography>
      </Box>

      {isUpdated && (
        <PlanUpdatedModal
          onClick={() => {
            setUnsubscribeOpen(false);
          }}
        />
      )}
    </Box>
  );
}

export default Step8;
