import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import arrow_right from '../../assets/images/unsubscribe/arrow_right.svg';

export default function ArticleCard({
  title,
  subTitle,
  badge,
  description,
  tip,
  image,
  onClick,
}: {
  title: string;
  subTitle: string;
  badge: string;
  description: JSX.Element;
  tip?: JSX.Element;
  image: string;
  onClick: () => void;
}) {
  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      sx={{
        border: '1px solid rgba(35, 38, 49, 0.10)',
        borderRadius: '20px',
        width: '100%',
        WebkitTapHighlightColor: 'transparent',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        img: {
          width: '100%',
        },
      }}
    >
      <img className="background-image" src={image} alt="decor" />
      <Box
        sx={{
          padding: '0 20px 20px',
          marginTop: '-19px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 'normal',
            display: 'flex',
            padding: '5px 15px',
            color: theme.palette.common.blue,
            width: 'max-content',
            borderRadius: '23px',
            background: 'rgba(37, 107, 253, 0.10)',
          }}
        >
          {badge}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: 'normal',
            margin: '10px 0',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: 'normal',
          }}
        >
          {subTitle}
        </Typography>

        <Box
          sx={{
            height: '1px',
            width: '100%',
            background: '#232631',
            opacity: 0.1,
            margin: '20px 0',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 'normal',
            color: theme.palette.text.secondary,
          }}
        >
          {description}
        </Typography>

        {tip && (
          <>
            <Box
              sx={{
                height: '1px',
                width: '100%',
                background: '#232631',
                opacity: 0.1,
                margin: '20px 0',
              }}
            />
            <Typography
              sx={{
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 'normal',
                color: theme.palette.text.secondary,
                span: {
                  color: theme.palette.text.primary,
                  fontWeight: 600,
                },
              }}
            >
              {tip}
            </Typography>
          </>
        )}

        <Button
          onClick={onClick}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            img: {
              width: '24px',
              position: 'absolute',
              right: '15px',
            },
            '&:hover': {
              background: theme.palette.common.blue,
            },
          }}
        >
          Learn more
          <img src={arrow_right} alt="arrow"></img>
        </Button>
      </Box>
    </Box>
  );
}
