import PageLayout from '../../components/PageLayout';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { AppRoutes } from '../../../../utils/constants';
import ArrowIcon from '../../../../assets/svg/arrow-left.svg';
import useScrollTop from '../../../../hooks/useScrollTop';
import { useNavigate } from 'react-router-dom';

export default function TopHacks() {
  useScrollTop();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <PageLayout title="Trend of 2025">
      <Box
        sx={{
          padding: '30px 20px 0',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: 'normal',
            textAlign: 'left',
            color: theme.palette.text.primary,
          }}
        >
          Top 5 Instagram Algorithm Hacks to Grow in 2025
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'left',
            margin: '20px 0',
            color: theme.palette.text.primary,
          }}
        >
          Instagram’s algorithm is shifting towards deeper engagement, meaningful interactions, and content longevity in
          2025. To stay ahead, you need to adapt your strategy based on how content is ranked and surfaced. Here’s how
          to hack the algorithm and maximize your growth.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            '& .wrapper': {
              borderRadius: '16px',
              background: '#F5F5F5',
              padding: '30px 20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              '& .title': {
                fontFamily: 'Avenir Next',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
              },
              '& .subTitle': {
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
                span: {
                  fontWeight: 600,
                },
              },
              '& .pointTitle': {
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
              },
              '& .point': {
                position: 'relative',
                paddingLeft: '23px',
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 'normal',
                color: theme.palette.text.primary,
                span: {
                  fontWeight: 600,
                },
                '&:before': {
                  position: 'absolute',
                  top: 6,
                  left: 0,
                  content: '""',
                  width: '8px',
                  height: '8px',
                  background: '#56AC14',
                  borderRadius: '50%',
                },
              },
              '& .redPoint': {
                position: 'relative',
                paddingLeft: '23px',
                fontFamily: 'Avenir Next',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: 'normal',
                color: '#F63C47',
                span: {
                  fontWeight: 600,
                },
                '&:before': {
                  position: 'absolute',
                  top: 6,
                  left: 0,
                  content: '""',
                  width: '8px',
                  height: '8px',
                  background: '#F63C47',
                  borderRadius: '50%',
                },
              },
            },
          }}
        >
          <Box className="wrapper">
            <Typography className="title">Hack 1: Stick to 1-2 High-Impact Stories Daily</Typography>
            <Typography className="pointTitle">Why?</Typography>
            <Typography className="subTitle">
              Instagram now prioritizes Story completion rates over how many Stories you post. If people skip through
              most of your Stories or drop off before watching them all, your future Stories will get less visibility.
            </Typography>
            <Typography className="pointTitle">The Fix</Typography>
            <Typography className="point">
              Post <span>1-2 engaging Stories</span> per day instead of flooding your feed with too many.
            </Typography>
            <Typography className="point">
              Use <span>interactive features</span> like Polls, Q&As, and Sliders to boost engagement.
            </Typography>
            <Typography className="point">
              <span>Keep it valuable!</span> Instead of a daily life dump, post Stories that spark interaction.
            </Typography>
            <Typography className="pointTitle">Example:</Typography>
            <Typography className="redPoint">
              Posting <span>10+ Stories</span> about your coffee, your workout, and your dog = people skip through.
            </Typography>
            <Typography className="point">
              Posting <span>1-2 Stories</span> with a Poll: “Morning grind: Coffee or matcha? ☕🍵 Vote below!” = More
              interaction, more algorithm love.
            </Typography>
            <Typography className="pointTitle">
              Bottom Line: The fewer Stories, the better - just make them interactive!
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Hack 2: Saves & Shares Are the New “Likes”</Typography>
            <Typography className="pointTitle">Why?</Typography>
            <Typography className="subTitle">
              Instagram now weighs Saves and Shares heavier than Likes when deciding which posts get more reach. If
              people Save or Share a post, it means they found value in it - so Instagram boosts it to more users.
            </Typography>
            <Typography className="pointTitle">The Fix</Typography>
            <Typography className="point">
              Create <span>Save-worthy content:</span> Lists, guides, tutorials, or aesthetic inspo.
            </Typography>
            <Typography className="point">
              Use <span>captions that drive Saves</span>, e.g., “📌 Save this for later so you don’t forget!”
            </Typography>
            <Typography className="point">
              Make <span>Shareable posts</span>: Memes, relatable content, or inspirational quotes.
            </Typography>
            <Typography className="pointTitle">Example:</Typography>
            <Typography className="point">
              A fitness coach posts: “5 Quick Workouts for Busy Mornings 🏋️‍♂️⏳” → People Save it to try later.
            </Typography>
            <Typography className="point">
              A travel influencer shares: “Best Hidden Gems in Paris 🇫🇷✨” → People Share it with friends.
            </Typography>
            <Typography className="pointTitle">
              Bottom Line: Saves = Long-term reach. Shares = More new eyes on your content. Prioritize both!
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Hack 3: Hook-First Reels: The First 3 Seconds Are Everything</Typography>
            <Typography className="pointTitle">Why?</Typography>
            <Typography className="subTitle">
              Instagram’s algorithm ranks Reels based on watch time. If people scroll past your Reel too quickly,
              Instagram assumes it’s boring and stops pushing it. But if they watch all the way through? Massive reach
              boost!
            </Typography>
            <Typography className="pointTitle">The Fix</Typography>
            <Typography className="point">
              Start with a <span>scroll-stopping hook</span> in the first 3 seconds
            </Typography>
            <Typography className="point">
              Add <span>text overlays</span> so even silent scrollers get hooked
            </Typography>
            <Typography className="point">
              Keep it <span>fast-paced</span> with quick cuts and engaging visuals
            </Typography>
            <Typography className="pointTitle">Example Hooks::</Typography>
            <Typography className="point">🔥 “You’re doing Instagram all wrong - here’s how to fix it!”</Typography>
            <Typography className="point">🔥 “This simple trick got me 100K followers in 3 months 🚀”</Typography>
            <Typography className="point">🔥 “STOP making this huge mistake in your captions! 😳”</Typography>
            <Typography className="pointTitle">
              Bottom Line: If your first 3 seconds aren’t gripping, your Reel is dead.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Hack 4: Comment Depth Matters More Than Volume</Typography>
            <Typography className="pointTitle">Why?</Typography>
            <Typography className="subTitle">
              Instagram now prioritizes comment quality over quantity. A flood of short, one-word comments ("Nice!"
              "🔥🔥🔥") won't boost your reach anymore. Instead, the algorithm favors meaningful conversations - longer,
              thoughtful comments signal that your post is engaging.
            </Typography>
            <Typography className="pointTitle">The Fix</Typography>
            <Typography className="point">
              <span>Ask deeper questions</span> in your captions to spark real conversations
            </Typography>
            <Typography className="point">
              <span>Reply with detailed responses</span> to keep the discussion going
            </Typography>
            <Typography className="point">
              <span>Use CTA’s like:</span> “Drop your experience below ⬇” or “Tell me your thoughts in 3 words!”
            </Typography>
            <Typography className="pointTitle">Example:</Typography>
            <Typography className="redPoint">
              <span>Weak Caption:</span> “Love this outfit! 😍” → Gets only “Cute!” “Nice!” comments.
            </Typography>
            <Typography className="point">
              <span>Strong Caption:</span> “Would you rock this for a night out or brunch? 🍷☀️ Let me know below!” →
              Gets thoughtful responses, boosting engagement.
            </Typography>
            <Typography className="pointTitle">
              Bottom Line: If your first 3 seconds aren’t gripping, your Reel is dead.
            </Typography>
          </Box>

          <Box className="wrapper">
            <Typography className="title">Hack 5: Instagram Search Optimization = The New Explore Page</Typography>
            <Typography className="pointTitle">Why?</Typography>
            <Typography className="subTitle">
              Instagram is shifting towards search-based content discovery - meaning people are using it more like
              Google to find specific posts. Hashtags alone won’t get you discovered anymore - you need to optimize for
              search.
            </Typography>
            <Typography className="pointTitle">The Fix</Typography>
            <Typography className="point">Use keyword-rich captions (not just emojis and hashtags)</Typography>
            <Typography className="point">
              Optimize your bio with searchable keywords (e.g., "Fitness Coach | Weight Loss Tips")
            </Typography>
            <Typography className="point">Add alt text to images for better ranking in Instagram Search</Typography>
            <Typography className="pointTitle">Example:</Typography>
            <Typography className="redPoint">
              <span>“✨ Outfit inspo! ✨ #fashion #style”</span>
            </Typography>
            <Typography className="point">
              ✨ Chic & casual fall outfit ideas! 🍂 Style inspo for effortless autumn looks. ✨ #FallFashion”
            </Typography>
            <Typography className="pointTitle">
              Bottom Line: Instagram is evolving into a search engine. Optimize or get left behind!
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: 'normal',
            textAlign: 'left',
            margin: '20px 0',
            color: theme.palette.text.primary,
          }}
        >
          Adapt now, and you’ll stay ahead of 99% of creators in 2025! Ready to grow smarter? Let’s go!
        </Typography>

        <Button
          sx={{
            position: 'relative',
            margin: '20px 0 0',
            width: '100%',
            padding: '19px',
            background: theme.palette.grey['300'],
            fontFamily: 'Avenir Next',
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '27.32px',
            textAlign: 'center',
            borderRadius: '12px',
            textTransform: 'initial',
            color: theme.palette.text.primary,
            '&:hover': {
              background: theme.palette.grey['300'],
            },
            '&:before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '15px',
              transform: 'translateY(-50%)',
              display: 'block',
              width: '24px',
              height: '24px',
              backgroundImage: `url(${ArrowIcon})`,
            },
          }}
          onClick={() => {
            navigate(AppRoutes.DASHBOARD);
          }}
        >
          To all articles
        </Button>
      </Box>
    </PageLayout>
  );
}
