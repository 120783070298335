import { Box, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';

function Step2({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setStep((prev) => prev + 1);
    }, 2500);
  }, [setStep]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <span className="plan_fetching_loader"></span>
      <Typography
        sx={{
          fontSize: '20px',
          color: theme.palette.text.primary,
          fontWeight: 600,
          fontFamily: 'Avenir Next',
          lineHeight: 'normal',
          marginTop: '20px',
        }}
      >
        Please wait
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          color: theme.palette.text.secondary,
          fontWeight: 400,
          fontFamily: 'Avenir Next',
          lineHeight: 'normal',
        }}
      >
        This may take a few seconds
      </Typography>
    </Box>
  );
}

export default Step2;
