import { Box, Button, Typography, useTheme } from '@mui/material';
import StepHeader from '../components/StepHeader';
import prof_report from '../../../assets/images/dashboard/unsubscribe/image_profile_report.webp';
import arrow_right from '../../../assets/images/unsubscribe/arrow_right.svg';
import mastery_course from '../../../assets/images/dashboard/unsubscribe/image_instagram_mastery_course.webp';
import { useAppState } from '../../../providers/AppProvider.hooks';
import NavigationCard from '../../Dashboard/components/Main/components/NavigationCard';
import { useNavigate } from 'react-router-dom';
import InsightsImage from '../../../assets/images/dashboard/navigation-card/insights-bg-card.webp';
import AdmirersImage from '../../../assets/images/dashboard/navigation-card/admirers.webp';
import HackImage from '../../../assets/images/unsubscribe/hack_image.webp';
import TrendImage from '../../../assets/images/unsubscribe/trend_image.webp';
import StalkersImage from '../../../assets/images/dashboard/navigation-card/stalkers.webp';
import { AppRoutes } from '../../../utils/constants';
import ArticleCard from '../../common/ArticleCard';

function Step5({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
  const { setUnsubscribeOpen, setManageSubscriptionsOpen } = useAppState();
  const navigate = useNavigate();

  const handleCardClick = (path: string) => {
    setUnsubscribeOpen(false);
    navigate(path);
  };

  const theme = useTheme();
  const offers = [
    {
      title: 'Detailed profile report',
      subTitle:
        'Uncover the secrets to boost your Instagram with a tailored report analysing your profile, and forget about low engagement',
      img: prof_report,
      btnText: 'Get profile report',
    },
    {
      title: 'Instagram Mastery Course',
      subTitle:
        'Learn the secrets of top influencers, develop a content strategy that works, and get insider tips on maximizing engagement',
      img: mastery_course,
      btnText: 'Get Instagram course',
    },
  ];
  return (
    <Box
      sx={
        {
          // overflow: 'hidden'
        }
      }
    >
      <StepHeader
        onClick={() => {
          setStep((prev) => prev - 2);
        }}
        title="Your Membership"
      />
      <Box
        sx={{
          padding: '30px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginBottom: '5px',
          }}
        >
          Wait! Have you made the most out of it?
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            color: theme.palette.text.secondary,
            marginBottom: '30px',
            textAlign: 'center',
          }}
        >
          Before you hit cancel, make sure you explored all your membership perks!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <ArticleCard
            badge="Algorithm Hack"
            title="Reels with trending audio get 3x more reach"
            subTitle="Here’s how to find the best ones!"
            image={HackImage}
            description={
              <>
                Go to the Posting, select your video
                <br />
                Open Music and tap on Trending
                <br />
                Post your Reel now to ride the trend early!
              </>
            }
            onClick={() => {
              handleCardClick(AppRoutes.TOP_HACKS);
            }}
          />
          <ArticleCard
            badge="Trend of 2025"
            title="Instagram SEO is the new goldmine"
            subTitle="Here’s why keywords in captions matter more than ever!"
            image={TrendImage}
            description={
              <>
                Instagram is shifting towards search-driven content discovery, just like Google.
                <br />
                <br />
                Posts with clear, keyword-rich captions (instead of just hashtags) are ranking higher in search.
              </>
            }
            tip={
              <>
                🔥 <span>Actionable tip:</span>
                <br />
                <br />
                Instead of just “New York sunset 🌅 #travel,” write:
                <br />
                <br />✅ “The best New York sunset spots you can’t miss! 🌅✨ Here’s where to go…”
              </>
            }
            onClick={() => {
              handleCardClick(AppRoutes.TOP_TRENDS);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: '40px 20px 40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: '#F5F5F5',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: '16px',
            background: 'linear-gradient(270deg, #FF9A84 0%, #EF5DCE 50%, #2B42DB 100%)',
            padding: '8px 15px 8px 15px',
            fontFamily: 'Avenir Next',
            fontWeight: 700,
            lineHeight: 'normal',
            fontSize: '10px',
            textTransform: 'uppercase',
            color: '#FFF',
            width: 'max-content',
          }}
        >
          TAILORED ARTICLES
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginTop: '15px',
          }}
        >
          Data-Backed Strategy Guides
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            marginTop: '5px',
            color: theme.palette.text.secondary,
            marginBottom: '30px',
            textAlign: 'center',
          }}
        >
          Boost your Instagram with tailored tips—no more low engagement!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
          }}
        >
          <NavigationCard
            title="Tips & Insights"
            description="Find out how to improve your account metrics"
            onClick={() => handleCardClick(AppRoutes.INSIGHTS)}
            timeLeft={null}
            image={InsightsImage}
          />
          <NavigationCard
            title="Secret Admirers"
            description="Know who’s watching your profile without following you"
            onClick={() => handleCardClick(`${AppRoutes.ARTICLES}/secret-admirers`)}
            timeLeft={null}
            image={AdmirersImage}
          />
          <NavigationCard
            title="Detect Stalkers"
            description="Learn how to identify, address, and prevent stalking or data breaches"
            onClick={() => handleCardClick(`${AppRoutes.ARTICLES}/detect-stalkers`)}
            timeLeft={null}
            image={StalkersImage}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: '40px 20px 0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            borderRadius: '16px',
            background: 'linear-gradient(270deg, #FF9A84 0%, #EF5DCE 50%, #2B42DB 100%)',
            padding: '8px 15px 8px 15px',
            fontFamily: 'Avenir Next',
            fontWeight: 700,
            lineHeight: 'normal',
            fontSize: '10px',
            textTransform: 'uppercase',
            color: '#FFF',
            width: 'max-content',
          }}
        >
          OUR SPECIAL OFFERS
        </Box>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '24px',
            color: theme.palette.text.primary,
            textAlign: 'center',
            marginTop: '15px',
          }}
        >
          Your Membership Extras
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Avenir Next',
            fontWeight: 400,
            lineHeight: 'normal',
            fontSize: '16px',
            marginTop: '5px',
            color: theme.palette.text.secondary,
            marginBottom: '20px',
            textAlign: 'center',
          }}
        >
          Get insider strategies for better engagement, content planning, and maximizing reach!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {offers.map((el, i) => (
            <Box
              key={i}
              sx={{
                position: 'relative',
                background: '#F5F2ED',
                borderRadius: '20px',
                padding: '30px 20px 20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                img: {
                  width: '182px',
                },
              }}
            >
              <img src={el.img} alt={el.title}></img>

              <Typography
                sx={{
                  fontFamily: 'Avenir Next',
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: 'normal',
                  color: '#232631',
                  textAlign: 'center',
                  marginTop: '20px',
                  userSelect: 'none',
                }}
              >
                {el.title}
              </Typography>

              <Typography
                sx={{
                  fontFamily: 'Avenir Next',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: 'normal',
                  color: '#8C8E96',
                  textAlign: 'center',
                  userSelect: 'none',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              >
                {el.subTitle}
              </Typography>
              <Button
                onClick={() => {
                  setUnsubscribeOpen(false);
                  setManageSubscriptionsOpen(true);
                }}
                sx={{
                  border: 'none',
                  borderRadius: '12px',
                  background: theme.palette.common.blue,
                  color: '#fff',
                  height: '60px',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  fontSize: '16px',
                  width: '100%',
                  cursor: 'pointer',
                  textTransform: 'initial',
                  fontFamily: 'Avenir Next',
                  transition: 'all 0.3s ease',
                  WebkitTapHighlightColor: 'transparent',
                  display: 'flex',
                  justifyContent: 'center',
                  img: {
                    width: '24px',
                    position: 'absolute',
                    right: '15px',
                  },
                  '&:hover': {
                    background: theme.palette.common.blue,
                  },
                }}
              >
                {el.btnText}
                <img src={arrow_right} alt="arrow"></img>
              </Button>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            height: '1px',
            width: '100%',
            background: '#232631',
            opacity: 0.1,
            margin: '40px 0',
          }}
        />
        <Button
          onClick={() => {
            setUnsubscribeOpen(false);
          }}
          sx={{
            border: 'none',
            borderRadius: '12px',
            background: theme.palette.common.blue,
            color: '#fff',
            height: '60px',
            fontWeight: 600,
            lineHeight: 'normal',
            fontSize: '16px',
            width: '100%',
            cursor: 'pointer',
            textTransform: 'initial',
            fontFamily: 'Avenir Next',
            transition: 'all 0.3s ease',
            WebkitTapHighlightColor: 'transparent',
            '&:hover': {
              background: theme.palette.common.blue,
            },
          }}
        >
          Keep me subscribed
        </Button>
        <Typography
          onClick={() => {
            setStep((prev) => prev + 1);
          }}
          sx={{
            fontSize: '14px',
            color: theme.palette.text.secondary,
            textDecoration: 'underline',
            textDecorationColor: theme.palette.text.secondary,
            textDecorationThickness: '1px',
            fontWeight: 400,
            fontFamily: 'Avenir Next',
            lineHeight: 'normal',
            margin: '15px auto 20px',
            textAlign: 'center',
            cursor: 'pointer',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          I still want to opt out
        </Typography>
      </Box>
    </Box>
  );
}

export default Step5;
